import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'

import SiteTop from '@/components/SiteTop/SiteTop.vue'
import ScrollGameTop from '@/components/ScrollGame/GameTop.vue'
import SwipeGameTop from '@/components/SwipeGame/GameTop.vue'
import SwipeGameMain from '@/components/SwipeGame/GameMain.vue'
import SwipeGameEnd from '@/components/SwipeGame/GameEnd.vue'

/** 直アクセスを禁止する */
function denyDirectAccess(to, from) {
  if (from === START_LOCATION) { // 直アクセスだった場合
    return '/' // '/' へリダイレクトする
  }
}

const routes = [
  {
    path: '/',
    name: 'routerSiteTop',
    component: SiteTop,
  },
  {
    path: '/scroll_game/top',
    name: 'routerScrollGameTop',
    component: ScrollGameTop,
    beforeEnter: [denyDirectAccess],
  },
  {
    path: '/swipe_game/top',
    name: 'routerSwipeGameTop',
    component: SwipeGameTop,
    beforeEnter: [denyDirectAccess],
  },
  {
    path: '/swipe_game/main',
    name: 'routerSwipeGameMain',
    component: SwipeGameMain,
    beforeEnter: [denyDirectAccess],
  },
  {
    path: '/swipe_game/end',
    name: 'routerSwipeGameEnd',
    component: SwipeGameEnd,
    beforeEnter: [denyDirectAccess],
    props: route => ({ matching_count: route.query.matching_count })
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
