<template>
  <div class="header_space" />
  <transition name="slide-fade">
    <div v-if="show" class="scroll_game_top_contents">
      <div class="scroll_game_top_contents__title">スクロールゲームの実験</div>
      <div class="scroll_game_top_contents__mes">
        <br />
        試行錯誤の場
      </div>
    </div>
  </transition>
  <div class="footer_space" />
</template>

<script>
// キャンバス用コンポーネントの読み込み
export default {
  name: "ScrollGameTop",
  components: {},
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true; // might need this.$nextTick
  },
};
</script>

<style lang="scss">
.scroll_game_top_contents {
  width: 95%;
  max-width: 480px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &__title {
    font-size: 20px;
    font-weight: 600px;
    text-align: center;
  }

  &__mes {
    font-size: 14px;
    text-align: center;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-480px);
  opacity: 0;
}
</style>

  