<template>
  <div class="header_space"></div>
  <div class="status_space">
    <div class="status_space__target">
      <img
        :src="
          require('@/assets/images/swipe_game/item0' +
            this.target_slide_no +
            '.png')
        "
        alt="Slide 1"
        class="status_space__target__image"
      />
    </div>
    <div class="status_space__score">
      下の画像を<br />
      左右にスワイプして<br />
      左と同じ画像を<br />
      見つけよう！<br />
      <p class="status_space__score__hit_count">
        （発見数：{{ this.matching_count }}）
      </p>
      <p v-if="countdown > 0" class="status_space__score__countdown">
        残り時間: {{ formatTime(countdown) }}秒
      </p>
      <p v-else>Countdown is over!</p>
    </div>
  </div>
  <div class="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          :src="require('@/assets/images/swipe_game/item00.png')"
          alt="Slide 0"
          class="swiper-slide__item"
        />
      </div>
      <div class="swiper-slide">
        <img
          :src="require('@/assets/images/swipe_game/item01.png')"
          alt="Slide 1"
          class="swiper-slide__item"
        />
      </div>
      <div class="swiper-slide">
        <img
          :src="require('@/assets/images/swipe_game/item02.png')"
          alt="Slide 2"
          class="swiper-slide__item"
        />
      </div>
      <div class="swiper-slide">
        <img
          :src="require('@/assets/images/swipe_game/item03.png')"
          alt="Slide 3"
          class="swiper-slide__item"
        />
      </div>
      <div class="swiper-slide">
        <img
          :src="require('@/assets/images/swipe_game/item04.png')"
          alt="Slide 4"
          class="swiper-slide__item"
        />
      </div>
      <div class="swiper-slide">
        <img
          :src="require('@/assets/images/swipe_game/item05.png')"
          alt="Slide 5"
          class="swiper-slide__item"
        />
      </div>
      <div class="swiper-slide">
        <img
          :src="require('@/assets/images/swipe_game/item06.png')"
          alt="Slide 6"
          class="swiper-slide__item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

// Import Swiper styles
import "swiper/css";

export default {
  name: "GameMain",
  components: {},
  data() {
    return {
      countdown: 60000, // カウントダウンの初期値（ミリ秒）
      // countdown: 2000, // カウントダウンの初期値（ミリ秒）
      active_slide_no: 0,
      target_slide_no: 2,
      matching_count: 0,
    };
  },
  mounted() {
    // Swiper インスタンスを初期化
    this.swiper = new Swiper(".swiper", {
      slidesPerView: 1, // 1つのスライドを表示
    });

    // Swipeが行われたときに呼び出す
    this.swiper.on("slideChange", () => {
      this.handleActiveIndexChange(this);
    });

    this.interval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown -= 100; // 1秒ごとに減少
      } else {
        clearInterval(this.interval);
        console.log("main matching_count:" + this.matching_count);
        this.$router.push({
          name: "routerSwipeGameEnd",
          path: "/swipe_game/end",
          query: { matching_count: this.matching_count },
        });
      }
    }, 100);
  },
  methods: {
    handleActiveIndexChange(varuable) {
      console.log("Handle Active Index Change:", varuable.swiper.activeIndex);
      this.active_slide_no = varuable.swiper.activeIndex;

      if (this.active_slide_no == this.target_slide_no) {
        while (this.active_slide_no == this.target_slide_no) {
          this.target_slide_no = Math.floor(Math.random() * 5) + 1;
        }
        if (!this.game_over) {
          this.matching_count++;
        }
      }
    },
    formatTime(milliseconds) {
      const totalMilliseconds = milliseconds;
      const totalSeconds = Math.floor(totalMilliseconds / 1000);
      const remainingSeconds = totalSeconds % 60;
      return `${String(remainingSeconds).padStart(2, "0")}`;
    },
  },
};
</script>

<style lang="scss">
.status_space {
  height: 200px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  background-image: url("../../assets/images/swipe_game/status_back.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;

  &__target {
    width: 50%;

    background-image: url("../../assets/images/swipe_game/target.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 70%;

    &__image {
      padding-top: 10px;
      height: 70%;
    }
  }

  &__score {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
      1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;

    &__hit_count {
      color: rgb(0, 129, 28);
      font-size: 16px;
    }

    &__countdown {
      font-size: 18px;
    }
  }
}

.swiper {
  height: 600px;
  background-color: #ffffff;
}

.swiper-wrapper {
  /* wrapperのサイズを調整 */
}

.swiper-slide {
  /* スライドのサイズを調整、中身のテキスト配置調整、背景色 */
  color: #ffffff;
  text-align: center;
  background-image: url("../../assets/images/swipe_game/game_back.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

  &__item {
    width: 70%;
  }
}

.swiper-slide:nth-child(1) {
  // background-color: #757575;
}

.swiper-slide:nth-child(2) {
  // background-color: #ff5733;
}

.swiper-slide:nth-child(3) {
  // background-color: #33ff57;
}

.swiper-slide:nth-child(4) {
  // background-color: #ffa933;
}

.swiper-slide:nth-child(5) {
  // background-color: #3399ff;
}

.swiper-slide:nth-child(6) {
  // background-color: #a933ff;
}

.swiper-slide:nth-child(7) {
  // background-color: #757575;
}
</style>
