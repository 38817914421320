<template>
  <div class="footer-base">
    <div class="footer-base__content">
      <p class="footer-base__content__item">
        <img
          :src="require('@/assets/images/footer/item_free.png')"
          alt="Logo"
          class="footer-base__content__item__icon"
        /><br />
        検討中
      </p>
      <p class="footer-base__content__item">
        <img
          :src="require('@/assets/images/footer/item_free.png')"
          alt="Logo"
          class="footer-base__content__item__icon"
        /><br />
        検討中
      </p>
      <p class="footer-base__content__main_item">
        <router-link to="/">
          <img
            :src="require('@/assets/images/footer/item_top.png')"
            alt="Logo"
            class="footer-base__content__item__icon"
          /><br />
        </router-link>
        トップ
      </p>
      <p class="footer-base__content__item">
        <template v-if="prd_mode">
          <img
            :src="require('@/assets/images/footer/item_free.png')"
            alt="Logo"
            class="footer-base__content__item__icon"
          /><br />
          検討中
        </template>
        <template v-else>
          <router-link to="/scroll_game/top">
            <img
              :src="require('@/assets/images/footer/item_game.png')"
              alt="Logo"
              class="footer-base__content__item__icon"
            /><br />
          </router-link>
          実験中
        </template>
      </p>
      <p class="footer-base__content__item">
        <template v-if="prd_mode">
          <img
            :src="require('@/assets/images/footer/item_free.png')"
            alt="Logo"
            class="footer-base__content__item__icon"
          /><br />
          検討中
        </template>
        <template v-else>
          <router-link to="/swipe_game/top">
            <img
              :src="require('@/assets/images/footer/item_swipe_game.png')"
              alt="Logo"
              class="footer-base__content__item__icon"
            /><br />
          </router-link>
          スワイプ
        </template>
      </p>
    </div>
  </div>
</template>

<script>
export const MODE = process.env.NODE_ENV;
export default {
  name: "SiteFooter",
  data() {
    return {
      prd_mode: MODE === "production" ? true : false,
    };
  },
  mounted() {
    console.log(process.env.NODE_ENV);
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

.footer-base {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  &__content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
    height: 96px;
    background-image: url("../assets/images/footer/footer.png");
    background-position: center;
    background-size: auto 100%;
    align-items: flex-end;

    &__main_item {
      padding-top: 14px;
      float: left;
      min-width: 28%;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      bottom: 0px;
      line-height: 1;

      &__icon {
        max-height: 64px;
        margin: 0;
        padding: 0;
      }
    }

    &__item {
      padding-top: 18px;
      float: left;
      min-width: 18%;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      bottom: 0px;
      line-height: 1;

      &__icon {
        max-height: 48px;
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>