<template>
  <div class="header_space" />
  <div class="contents">
    <transition name="slide-fade">
      <div v-if="show" class="contents__item">
        <div v-if="subscriber_count > 0" class="contents__item__top">
          <div class="contents__item__top__status">
            チャンネル登録者数 {{ subscriber_count }}人 -
            {{ video_count }}本の動画
          </div>
          <div class="contents__item__top__arrow">
            <img
              :src="require('@/assets/images/site_top/arrow.png')"
              alt="Logo"
              class="contents__item__top__arrow__image"
            />
          </div>
        </div>
        <div
          class="contents__item__youtube"
          @click="clickLink('https://www.youtube.com/@TonkiGames')"
          v-wave="{
            color: '#FFFFFF',
            easing: 'ease-out',
            duration: 0.8,
            dissolveDuration: 0.15,
            initialOpacity: 0.8,
            finalOpacity: 0.0,
            cancellationPeriod: 75,
            trigger: 'auto',
            tagName: 'div',
          }"
        ></div>
        <div class="contents__item__message">
          『とんきゲームズ』メインコンテンツ！<br />札幌在住のとんきが、ゲーム実況や旅行の様子を紹介しています！
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="show" class="contents__item">
        <div class="contents__item__top">
          <div class="contents__item__top__status">
            Youtube活動の裏側をBlogで紹介
          </div>
          <div class="contents__item__top__arrow">
            <img
              :src="require('@/assets/images/site_top/arrow.png')"
              alt="Logo"
              class="contents__item__top__arrow__image"
            />
          </div>
        </div>
        <div
          class="contents__item__blog"
          @click="clickLink('https://blog.tonki-games.com')"
          v-wave="{
            color: '#FFFFFF',
            easing: 'ease-out',
            duration: 0.8,
            dissolveDuration: 0.15,
            initialOpacity: 0.8,
            finalOpacity: 0.0,
            cancellationPeriod: 75,
            trigger: 'auto',
            tagName: 'div',
          }"
        ></div>
        <div class="contents__item__message">
          『とんきゲームズ』のこだわりがここに！<br />動画制作の流れや編集ツールを紹介しています！
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="show" class="contents__item">
        <div
          class="contents__item__linestamp"
          @click="
            clickLink('https://store.line.me/stickershop/author/2720999/ja')
          "
          v-wave="{
            color: '#00c300',
            easing: 'ease-out',
            duration: 0.8,
            dissolveDuration: 0.15,
            initialOpacity: 0.8,
            finalOpacity: 0.0,
            cancellationPeriod: 75,
            trigger: 'auto',
            tagName: 'div',
          }"
        ></div>
        <div class="contents__item__message">
          『とんきゲームズ』のLINEスタンプです！<br />友達との会話で使えるスタンプが盛り沢山！
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="show" class="contents__item">
        <div
          class="contents__item__twitter"
          @click="clickLink('https://twitter.com/TonkiGames')"
          v-wave="{
            color: '#FFFFFF',
            easing: 'ease-out',
            duration: 0.8,
            dissolveDuration: 0.15,
            initialOpacity: 0.8,
            finalOpacity: 0.0,
            cancellationPeriod: 75,
            trigger: 'auto',
            tagName: 'div',
          }"
        ></div>
        <div class="contents__item__message">
          『とんきゲームズ』の最新ニュースはこちら！<br />動画の配信情報や、とんきの日常を知ることができます！
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="show" class="contents__item">
        <div
          class="contents__item__instagram"
          @click="clickLink('https://www.instagram.com/tonkigames/')"
          v-wave="{
            color: '#808080',
            easing: 'ease-out',
            duration: 0.8,
            dissolveDuration: 0.15,
            initialOpacity: 0.8,
            finalOpacity: 0.0,
            cancellationPeriod: 75,
            trigger: 'auto',
            tagName: 'div',
          }"
        ></div>
        <div class="contents__item__message">
          『とんき』日常の写真や動画の投稿はこちら！<br />旅行先で撮影したものを見ることができます！
        </div>
      </div>
    </transition>
  </div>
  <div class="footer_space" />
</template>

<script>
import axios from "axios";

export default {
  name: "TopMain",
  data() {
    return {
      show: false,
      subscriber_count: 0,
      video_count: 0,
      view_count: 0,
    };
  },
  mounted() {
    this.show = true;

    axios
      .get("https://restapi.tonki-games.com/youtube_channel/channel_info.php")
      .then((response) => this.setChannelInfo(response))
      .catch((error) => console.log(error));
  },
  methods: {
    clickLink: function (url) {
      setTimeout(() => {
        window.open(url, "_blank");
      }, 500);
    },
    setChannelInfo: function (response) {
      this.subscriber_count = response.data.subscriber_count;
      this.video_count = response.data.video_count;
      this.view_count = response.data.view_count;
      // console.log("subscriber_count: " + this.subscriber_count);
      // console.log("video_count: " + this.video_count);
      // console.log("view_count: " + this.view_count);
    },
  },
};
</script>

<style lang="scss">
.header {
  background-color: #e2e2e2;
  padding: 10px;
}

.contents {
  width: 90%;
  max-width: 480px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transition: all 0.5s 0s ease;

  &__item {
    &__top {
      font-size: 12px;
      text-align: right;
      float: right;

      &__status {
        padding: 0px 10px 0px 10px;
        background-color: #fff;
        border: 2px solid #ccc;
        border-radius: 10px 10px 10px 10px; /* 左上・右上・右下・左下 */
      }

      &__arrow {
        margin-top: -3px;
        margin-right: 20px;
        margin-bottom: -4px;
        &__image {
          height: 10px;
        }
      }
    }

    &__message {
      font-size: 14px;
      line-height: 1.2;
      margin-top: 8px;
      margin-bottom: 14px;
    }

    &__youtube {
      clear: both;
      background-image: url("../../assets/images/site_top/contents_youtube.png");
      background-position: center;
      background-size: auto 100%;
      height: 120px;
      border: 2px solid #ccc;
      border-radius: 10px 10px 10px 10px; /* 左上・右上・右下・左下 */
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
    }

    &__blog {
      clear: both;
      background-image: url("../../assets/images/site_top/contents_blog.png");
      background-position: center;
      background-size: auto 100%;
      height: 120px;
      border: 2px solid #ccc;
      border-radius: 10px 10px 10px 10px; /* 左上・右上・右下・左下 */
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
    }

    &__twitter {
      background-image: url("../../assets/images/site_top/contents_twx.png");
      background-position: center;
      background-size: auto 100%;
      height: 120px;
      border: 2px solid #ccc;
      border-radius: 10px 10px 10px 10px; /* 左上・右上・右下・左下 */
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
    }

    &__instagram {
      background-image: url("../../assets/images/site_top/contents_instagram.png");
      background-position: center;
      background-size: auto 100%;
      height: 120px;
      border: 2px solid #ccc;
      border-radius: 10px 10px 10px 10px; /* 左上・右上・右下・左下 */
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
    }

    &__linestamp {
      background-image: url("../../assets/images/site_top/contents_linestamp.png");
      background-position: center;
      background-size: auto 100%;
      height: 120px;
      border: 2px solid #ccc;
      border-radius: 10px 10px 10px 10px; /* 左上・右上・右下・左下 */
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-480px);
    opacity: 0;
  }
}
</style>

