<template>
  <div class="header_space" />
  <transition name="slide-fade">
    <div v-if="show" class="swipe_game_top_contents">
      <div class="swipe_game_top_contents__title">ミニゲーム</div>
      <div class="swipe_game_top_contents__item" />
      <div class="swipe_game_top_contents__mes">
        <br />
        制限時間は60秒!!<br /><br />
        画面を左右にスワイプして<br />指定された「とんき」を見つけよう！
      </div>
      <router-link to="/swipe_game/main">
        <img
          :src="require('@/assets/images/swipe_game/start.png')"
          alt="Start"
          class="swipe_game_top_contents__start"
        />
      </router-link>
    </div>
  </transition>
  <div class="footer_space" />
</template>

<script>
// キャンバス用コンポーネントの読み込み
export default {
  name: "SwipeGameTop",
  components: {},
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true; // might need this.$nextTick
  },
};
</script>

<style lang="scss">
.swipe_game_top_contents {
  width: 95%;
  max-width: 480px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &__title {
    font-size: 20px;
    font-weight: 600px;
    text-align: center;
  }

  &__item {
    background-image: url("../../assets/images/swipe_game/logo.png");
    background-position: center;
    background-size: auto 100%;
    height: 120px;
    border: 2px solid #888;
  }

  &__mes {
    font-size: 14px;
    text-align: center;
  }

  &__start {
    width: 90%;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-480px);
  opacity: 0;
}
</style>

  