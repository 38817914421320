<template>
  <v-app class="smart-app">
    <v-main>
      <SmartApp />
    </v-main>
  </v-app>
</template>

<script>
import SmartApp from "./components/SmartApp.vue";

export default {
  name: "App",

  components: {
    SmartApp,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

.smart-app {
  background-color: #453c32;
}
</style>