<template>
  <v-app class="smart-app">
    <SiteHeader> </SiteHeader>
    <div class="smart-app__main-content">
      <router-view />
    </div>
    <SiteFooter> </SiteFooter>
  </v-app>
</template>

<script>
import SiteHeader from "./SiteHeader.vue";
import SiteFooter from "./SiteFooter.vue";

export default {
  components: {
    SiteHeader,
    SiteFooter,
  },
  data: () => ({}),
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, arial, "ヒラギノ丸ゴ ProN W4", "ヒラギノ丸ゴ Pro",
    Futura, "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ ProN W3",
    "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", HG丸ｺﾞｼｯｸMPRO, HGMaruGothicMPRO, sans-serif;
  background-color: #453c32;
  background-image: url("../assets/images/back.png");
  background-position: center;
  background-attachment: fixed;
  background-repeat: repeat-y;
}

.smart-app {
  &__main-content {
    width: 100%;
    max-width: 600px;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.header_space {
  height: 64px;
}

.footer_space {
  height: 96px;
}

</style>