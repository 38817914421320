<template>
  <div class="header_space" />
  <transition name="slide-fade">
    <div v-if="show" class="swipe_game_end_contents">
      <div class="swipe_game_end_contents__title">結果発表</div>
      <div class="swipe_game_end_contents__mes">
        <br />
        発見した回数は{{ matching_count }}回でした!!<br /><br />
      </div>
    </div>
  </transition>
  <div class="footer_space" />
</template>

<script>
export default {
  name: "SwipeGameEnd",
  components: {},
  props: {
    matching_count: String,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
};
</script>

<style lang="scss">
.swipe_game_end_contents {
  width: 95%;
  max-width: 480px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &__title {
    font-size: 20px;
    font-weight: 600px;
    text-align: center;
  }

  &__mes {
    font-size: 16px;
    text-align: center;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-480px);
  opacity: 0;
}
</style>

  