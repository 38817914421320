import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import { loadFonts } from './plugins/webfontloader'
import VWave from 'v-wave'
import VueGtag from 'vue-gtag'
export const MODE = process.env.NODE_ENV;

loadFonts()

// 検証用GA
let analytics_id = "G-8D8LQGPEX3"

// 商用環境の場合、商用GA
if (MODE === "production") { analytics_id = "G-QFTN0G9CH4" }

createApp(App)
  .use(router)
  .use(VWave)
  .use(
    VueGtag,
    {
      config: { id: analytics_id },
    },
  )
  .mount('#app')