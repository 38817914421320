<template>
  <div class="header-base">
    <div class="header-base__content">
      <img
        :src="require('@/assets/images/header/logo.png')"
        alt="Logo"
        class="header-base__content__logo"
      />
    </div>
  </div>
</template>

<script></script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

.header-base {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__content {
    background: #615453;
    width: 100%;
    max-width: 600px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 64px; // 1行の高さを64にすることで中央とする

    &__logo {
      height: 64px;
    }
  }
}
</style>